import { render, staticRenderFns } from "./ReportReview.vue?vue&type=template&id=02a1ddb3"
import script from "./ReportReview.vue?vue&type=script&lang=js"
export * from "./ReportReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports