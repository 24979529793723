<template>
  <v-form ref="labForm">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row :style="{'padding-bottom': matrix_height + 'px'}">
        <v-col
          md="3"
          lg="4"
        >
          <v-card :loading="loading.report">
            <v-card-title class="d-flex justify-space-between">
              <div>Report Information</div>
              <client-icon
                v-if="report.client && $auth.check({ clients: ['*', 'view','edit'] })"
                :key="report.client.uuid"
                :icon="report.client.icon"
                :name="report.client.name"
              />
            </v-card-title>
            <v-card-text>
              <v-row
                class="d-flex justify-space-between"
                no-gutters
              >
                <v-col
                  class="mb-0 py-0"
                >
                  <v-skeleton-loader
                    :loading="loading.report"
                    type="list-item-avatar-two-line@4"
                    fade-transition
                  >
                    <span>
                      <v-list
                        class="pb-0"
                        dense
                      >
                        <v-list-item selectable>
                          <v-list-item-icon class="mr-4">
                            <v-icon size="19">
                              fal fa-fw fa-user
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Ordered By</v-list-item-subtitle>
                            <v-list-item-title data-private>
                              <patient-name
                                :patient="report.user"
                              />
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item selectable>
                          <v-list-item-icon class="mr-4">
                            <v-icon size="19">
                              fal fa-fw fa-file-medical
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Order Date</v-list-item-subtitle>
                            <v-list-item-title>{{ report.created_at | moment("LL LT") }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                          v-if="report.meta"
                          selectable
                        >
                          <v-list-item-icon class="mr-4">
                            <v-icon size="19">
                              fal fa-fw fa-calendar-day
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Start Date</v-list-item-subtitle>
                            <v-list-item-title>{{ report.meta.start_date | moment("LL") }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                          v-if="report.meta"
                          selectable
                        >
                          <v-list-item-icon class="mr-4">
                            <v-icon size="19">
                              fal fa-fw fa-calendar-day
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>End Date</v-list-item-subtitle>
                            <v-list-item-title>{{ report.meta.end_date | moment("LL") }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                      </v-list>
                    </span>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider v-if="report.status == 'needs review'" />

            <div class="pb-1">
              <status-notes
                v-if="report.status == 'needs review'"
                status-text="needs review"
                :statuses="report.statuses"
              />
            </div>
          </v-card>
        </v-col>
        <v-col
          md="9"
          lg="8"
        >
          <v-card
            :loading="loading.pdf"
            min-height="600"
          >
            <v-skeleton-loader
              :loading="loading.pdf"
              type="image"
            >
              <pdf
                ref="pdf"
                :src="pdf.src"
                :page="pdf.page"
                style="width:100%; max-height:50%"
                @num-pages="pdf.pages = $event"
              />
              <v-btn
                v-if="pdf.pages > 1"
                :disabled="pdf.page === 1"
                fab
                top
                left
                absolute
                x-small
                class="mt-2"
                @click="pdf.page--"
              >
                <v-icon
                  small
                  class="ml-n1 mt-1"
                >
                  fas fa-fw fa-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                v-if="pdf.pages > 1"
                :disabled="pdf.page === pdf.pages"
                fab
                top
                left
                absolute
                x-small
                class="mt-2 ml-9"
                @click="pdf.page++"
              >
                <v-icon
                  small
                  class="mt-1"
                >
                  fas fa-fw fa-chevron-right
                </v-icon>
              </v-btn>
            </v-skeleton-loader>
            <v-card-actions>
              <v-col v-if="$auth.check({ reports: ['approve'] })">
                <flag-for-review
                  :uuid="report.uuid"
                  type="reports"
                  class="mr-2"
                  :go-back="true"
                />
                <v-btn
                  color="blue darken-2"
                  icon
                  outlined
                  title="Download"
                  :href="report.pdf"
                  download
                >
                  <i class="fas fa-file-download fa-lg" />
                </v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  v-if="report.status != 'approved' && $auth.check({ reports: ['approve'] })"
                  color="secondary"
                  :loading="loading.button"
                  :disabled="loading.pdf"
                  @click="approveReport()"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >
                    mdi-check
                  </v-icon>
                  {{ report.status == 'approved' ? 'Approved' : 'Approve' }}
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  export default {
    metaInfo () {
      return {
        title: 'Lab Result ' + this.report.name + ' | Review',
      }
    },
    components: {
      FlagForReview: () => import('@/components/lab_result/FlagForReview.vue'),
      StatusNotes: () => import('@/components/lab_result/StatusNotes.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      PatientName: () => import('@/components/patient/PatientName.vue'),
      pdf: () => import('vue-pdf'),
    },
    data: () => ({
      loading: {
        report: false,
        button: false,
        pdf: false,
      },
      valid: true,
      report: {},
      pdf: {
        src: null,
        page: 1,
        pages: null,
      },
      matrix_height: 25,
    }),
    computed: {
      fileName () {
        return this.report.name + ' - ' + this.report.created_at + ' - Arkstone.pdf'

      },
    },
    created () {
      this.fetchReport()
      this.fetchPdf()
    },
    methods: {
      approveReport () {
        this.loading.button = true
        this.axios.post('reports/' + this.$route.params.uuid + '/approve')
          .then(() => {
              this.$router.push({ name: 'Reports' });
              this.$toast.success("The report has been approved.")
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.button = false })
      },
      fetchReport () {
        this.loading.report = true
        const promise = this.axios.get('reports/' + this.$route.params.uuid)

        return promise.then((response) => {
          this.report = response.data;
        })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.report = false })
      },
      fetchPdf () {
        this.loading.pdf = true
        this.axios.get('/reports/' + this.$route.params.uuid + '/pdf', {
          params: {
            output: 'base64',
          },
        })
          .then((res) => {
            this.pdf.src = 'data:application/pdf;base64,' + res.data.report_content
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.pdf = false })
      },
    },
  }
</script>

